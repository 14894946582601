<template>
    <div class="club-container" v-if="info">
        <div class="title">{{ "移动机器人创新俱乐部" | language }}</div>
        <div class="desc">{{ info.desc }}</div>
        <div class="list-title">{{ "学生科创俱乐部" | language }}</div>
        <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide v-for="i in labsLength" :key="'slide' + i">
                <div class="club-list">
                    <div
                        v-for="item in info.clubs.slice(i * 9 - 9, 9 * i)"
                        :key="item._id"
                        @click="handleClick(item)"
                        class="club-item click-op"
                    >
                        <img
                            v-real-img="item.logo.src"
                            alt=""
                            class="club-img"
                        />
                        <div class="item-name">{{ item.name }}</div>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
    data() {
        return {
            info: null,
            swiperOptions: {
                autoHeight: false,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                },
                direction: "vertical"
            }
        };
    },
    created() {
        this.fetchData();
    },
    computed: {
        labsLength() {
            let len = this.info?.clubs?.length ?? 0;
            return len > 0 ? Math.ceil(len / 9) : 0;
        }
    },
    methods: {
        handleClick(item) {
            this.$router.push(`/base/innovation/e-club/${item._id}`);
        },
        async fetchData() {
            let res = await this.$http.get("/innovation/club/summary");
            this.info = res;
        }
    }
};
</script>

<style lang="less" scoped>
.club-container {
    text-align: left;
    .swiper-container {
        width: 100%;
        height: 240px;
        .club-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
            .club-item {
                width: 33.33%;
                padding: 10px;
                display: flex;
                align-items: center;
                .club-img {
                    margin-right: 15px;
                    width: 60px;
                    height: 60px;
                    background: #d8d8d8;
                    object-fit: cover;
                }
                .club-name {
                    width: 166px;
                    font-size: 16px;
                    opacity: 0.9;
                    line-height: 29px;
                }
            }
        }
    }
    .title {
        opacity: 0.9;
        font-size: 30px;
        letter-spacing: 0;
        line-height: 32px;
        margin-bottom: 20px;
    }
    .desc {
        margin-bottom: 40px;
        opacity: 0.6;
        font-size: 16px;

        letter-spacing: 0;
        line-height: 29px;
    }
    .list-title {
        opacity: 0.9;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 32px;
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 960px) {
    .club-container {
        .row-container {
            .plan {
                .plan-list {
                    width: 100%;
                }
            }
        }
        .swiper-container {
            height: 360px;
            .club-list {
                align-items: stretch;
                .club-item {
                    flex-direction: column;
                    padding: 5px;
                    .club-img {
                        margin-right: 0;
                    }
                    .item-name {
                        margin-top: 5px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>
